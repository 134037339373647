var slick = require('../vendor/slick.min.js');
require('jquery-ui');
var Slider = require('./sliders.js');
var Utility = require('./utilities.js');
// var AOS = require('aos');
import {
    CountUp
} from 'countup.js';
var SimplePopup = require('../vendor/simplepopup/simplePopup.js');

$(document).ready(function () {
    // zakomentirati da se isključi feature widget

    // Utility.definePatternHeight();
    // AOS.init();
    Slider.headerSlider('init');
    Slider.carouselSlider('init');
    Slider.sliders('init');
    Slider.referencesSlider('init');
    Slider.commentsSlider('init');
    Slider.horecaSlider('init');

    // $('.slider_item').each(function (e) {
    //     setTimeout(() => {
    //         var sliderImg = parseInt($(this).children('.slider-img').height());
    //         var sliderContent = parseInt($(this).children('.item-content').height());

    //         if (sliderImg < sliderContent) {
    //             $(this).removeClass('vertical-center');
    //         }
    //         if ($(this).hasClass('slider-item-right') && $(this).hasClass('vertical-center')) {
    //             if ($(window).width() > 769 - window_correction) {
    //                 var sliderHtml = $(this).html();
    //                 var sliderImg = $(this).children('.slider-img');
    //                 var sliderContent = $(this).children('.item-content');
    //                 $(this).empty();
    //                 $(this).append(sliderContent);
    //                 $(this).append(sliderImg);
    //             }
    //         }
    //     }, 4000);

    // })

    // Social share popup activation
    //
    $(document).on('click', '.social-popup-trigger', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var targetEl = $(this).parent().find('.social-popup');
        targetEl.toggleClass('active');
        if (targetEl.hasClass('on')) {
            targetEl.removeClass('on');
        } else {
            setTimeout(function () {
                targetEl.addClass('on');
            }, 100);
        }
    });

    $(document).on('click', '#share-icon', function (e) {
        e.preventDefault();
        e.stopPropagation();
        // console.log('test click');
        var tarEl = $(this).parent().find('.social-icon-popup');
        tarEl.toggleClass('active');
        if (tarEl.hasClass('on')) {
            tarEl.removeClass('on');
        } else {
            setTimeout(function () {
                tarEl.addClass('on');
            }, 100)
        }
    });

    //
    // Anchor link scrollTop animation
    //
    $(document).on('click', '.anchor', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var id = $(this).attr('href');
        if ($(id).length) {
            $('html, body').animate({
                scrollTop: $(id).offset().top - 90
            });
        }
    });

    //
    // Comparison table responsive view
    //
    var products = $('.comparison-table .col-product');
    var tabs = $('#productTabs .nav-link');
    var window_correction = 17;

    function setActiveProduct() {
        if ($(window).width() < 769 - window_correction) {
            products.addClass('tab-pane fade');
            products.removeClass('show active');
            products.first().addClass('show active');
            tabs.removeClass('show active');
            tabs.first().addClass('show active');
        } else {
            products.removeClass('tab-pane fade show active');
            tabs.removeClass('show active');
        }
    }

    setActiveProduct();

    //
    // Set equal row height
    //

    function setEqualRowHeight(el, el2) {
        if ($(window).width() > 768) {
            var h;
            var arr = [];
            $(el).each(function (i) {
                h = $(this).height();
                arr.push(h);
            });

            $(el2).each(function () {
                $(this).find('li').each(function (i) {
                    $(this).height(arr[i]);
                });
            });
        }
    }

    setEqualRowHeight('.col-feature li', '.col-product');


    //
    // Navigation animation
    //
    var menu = $(document).find('[data-menu]').attr('data-menu');
    $(document).on('click', '[data-menu]', function (e) {
        e.preventDefault();
        $(menu).toggleClass('open');
        $(menu).find('.main-navigation').toggleClass('show');
        $('.nav-backdrop').toggleClass('show');
        $('body').toggleClass('menu-is-open');
    });

    //
    // Cookie set and link action
    //
    var cookie_name = 'seenCookieMessage';
    //CookieMonster.eraseCookie(cookie_name);
    /*$(document).on('click', '#btnCookie', function(e)  {
        e.preventDefault();
        e.stopPropagation();
        CookieMonster.setCookie(cookie_name, 'yes', 60);
        $('.cookie-component').addClass('close-cookie');
    });*/

    //
    // Close popups(...) when clicked outside of target element
    //
    $(document).on('click', function () {
        $('.social-popup').removeClass('active on');
    });

    //  Back to top anchor
    var bktt = $('#back_to_top');
    bktt.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 800);
    });

    // Back to top animation appearance on scroll
    $('body').on('scroll', function (e) {
        console.log(e, $(this));
        if ($(this).scrollTop > 400) {
            bktt.addClass('animate-in');
        } else {
            bktt.removeClass('animate-in');
        }
    });

    // All window resize functions go here
    if ($(window).width() > 769 - window_correction) {
        $(window).resize(function () {
            setActiveProduct();
            setEqualRowHeight('.col-feature li', '.col-product');
        });
    }

    // On tab select animation (the selected tab animates)
    $('#productTabs li').on('click', function () {
        var scroll = 0;
        if ($(this).offset().left > $(window).width() / 2) {
            scroll = $('#productTabs').scrollLeft() + $(this).width();
        } else {
            scroll = $('#productTabs').scrollLeft() - $(this).width();
        }

        $(this).parents('.nav-tabs').animate({
            scrollLeft: scroll
        }, 800);
    });



    // Definiranje visine contenta
    Utility.setEqualHeight('.carousel-item-title');
    Utility.setEqualHeight('.carousel-item-desc');
    Utility.setEqualHeight('.carousel-item-link');
    Utility.setEqualHeight('.grid-item-title');
    Utility.setEqualHeight('.grid-item-text');
    Utility.setEqualHeight('.blog-title');
    Utility.setEqualHeight('.blog-desc');
    Utility.setEqualHeight('.brand-img-wrapper');
    Utility.setEqualHeight('.brands_init .item-title');
    // Utility.setEqualHeight('.brands_init .item-desc');
    Utility.setEqualHeight('.partner_network_init .item-title');
    Utility.setEqualHeight('.partner_network_init .item-desc');
    // Kraj Definiranja visine contenta

    // REFERENCE  TAB SWITCHER
    $('.cat-btn').on('click', function (e) {
        e.preventDefault();
        var thisClass = $(this).attr('id');
        $('.all-btn').removeClass('active');
        $('.cat-btn').removeClass('active');
        $(this).addClass('active');
        $('.cat-sort').each(function () {
            if ($($(this)).hasClass(thisClass)) {
                $(this).show(400);
            } else {
                $(this).hide(400);
            }
        })
        // setTimeout(function () {
        //     AOS.init()
        // }, 500);

    });

    $('.all-btn').on('click', function (e) {
        e.preventDefault();
        $('.cat-btn').removeClass('active');
        $(this).addClass('active');
        $('.cat-sort').each(function () {
            $(this).show(400);
        })
        setTimeout(function () {
            AOS.init()
        }, 500);
    });

    var fullUrl = window.location.href;
    var referenceHash = window.location.hash;
    var referenceId = referenceHash.slice(1);
    $('.cat-btn').each(function () {
        if ($(this).attr('id') === referenceId) {
            $(this).trigger('click');
        }
    })

    var pageContactHash = window.location.hash;
    var pageContantName = pageContactHash.slice(1);

    if (pageContactHash) {
        $('#contact_subject').val(decodeURI(pageContantName));
    }

    if ($('.header-slider-item').length) {
        $('.header-slider-item').each(function (el) {
            if ($(this).find('.video').length) {
                var _this = $(this).find('.video')[0];
                var videoSrc = _this.getAttribute('data-src');

                _this.setAttribute('src', videoSrc)
            }
        })
    }

    if ($(window).width() < 769) {

        var dropdownTogglerW = $('.lang-wrapper .dropdown-toggle').width() + 'px';

        $('.lang-wrapper .dropdown-menu').css('width', dropdownTogglerW)
    }

    var termsCloseBtn = '<button class="terms-close simplePopupClose"><i class="fas fa-times"></i></button>'
    $('.terms-conditions-link').on('click', function (e) {
        e.preventDefault();
        var url = window.location.protocol + '//' + window.location.host + $(this).attr('href');
        // console.log(url);
        new SimplePopup({
            'contentClass': 'terms-and-conditions-popup',
            'content': {
                url: url
            },
            preloaderStyles: '#simplePopupPreloaderAfter,#simplePopupPreloaderBefore{content:"";position:absolute}#simplePopupPreloader{display:block;position:fixed;left:50%;top:50%;width:150px;height:150px;margin:-75px 0 0 -75px;border-radius:50%;border:3px solid transparent;border-top-color:#F00;-webkit-animation:spin 1.5s linear infinite;animation:spin 1.5s linear infinite;z-index:1001}#simplePopupPreloaderBefore{top:5px;left:5px;right:5px;bottom:5px;border-radius:50%;border:3px solid transparent;border-top-color:#F00;-webkit-animation:spin 2.5s linear infinite;animation:spin 2.5s linear infinite}#simplePopupPreloaderAfter{top:15px;left:15px;right:15px;bottom:15px;border-radius:50%;border:3px solid transparent;border-top-color:#F00;-webkit-animation:spin 1.5s linear infinite;animation:spin 1.5s linear infinite}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes spin{0%{-webkit-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg)}}',
            // 'contentHtmlId': 'terms-and-conditions-popup',
            'contentCss': {
                'width': 'auto',
                'padding': '0px',
            },
            onAfterOverlayShow: function (el, self) {
                self.preloaderStart(self.overlayClass);
            },
            onBeforeContentShow: function(el, self){
                // var elements = $(self.html);
                // var found = $('#content', elements);
                // console.log(found, 'test');
                // console.log(self.html, el);
                self.html = $(self.html).filter('#content');
            },
            onAfterContentShow: function (el, self) {
                self.preloaderEnd(self.overlayClass);
                // var content = $(el).find('#content');
                // $(el).html(content);
                $(el).append(termsCloseBtn);
                var elWidth = $(el).width() / 2;
                var leftPos = 'calc(50% - ' + elWidth + 'px)';
                $(el).css('left', leftPos);
                $('.gdpr-cookie-open').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    // window.createPopup();
                    sc.deleteCookie('dgaa_' + sessID);
                    sc.deleteCookie('dgs_' + sessID);
                    sc.deleteCookie('dgd_' + sessID);
                    window['ga-disable-' + ga.getAll()[0].get('trackingId')] = false;
                    window.createPopup();
                });
                setTimeout(function () {
                    $(el).css('visibility', 'visible');
                }, 300)
            },
        });
    })
    if ($(window).width() > 768) {
        $('.partners-img-wrapper').each(function (i, el) {
            if (i == 1) {
                $(el).css('margin-top', '25px');

            }

        })
    }
    horecaBtnPos();

});

$(window).resize(function () {
    horecaBtnPos();
    if ($(window).width() < 769) {

        var dropdownTogglerW = $('.lang-wrapper .dropdown-toggle').width() + 'px';

        $('.lang-wrapper .dropdown-menu').css('width', dropdownTogglerW)
    }
})

$(window).on('scroll', Utility.defineBackToTop);
$(window).on('scroll', function () {
    if ($('.dm_counter_slider').length) {
        var eT = $('.dm_counter_slider').offset().top,
            eH = $('.dm_counter_slider').outerHeight(),
            wH = $(window).height(),
            wS = $(this).scrollTop();
        if (wS > (eT + eH - wH)) {
            $(window).off('scroll');

            // animateCounter();
            countNumber();
        }
    }

})
// YouTube custom play button
// Fades out cover image and button on click and starts video that is behind button and/or image
$(document).on('click', '#video-play-lazy', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    var iframe = document.createElement('iframe');
    var embed = $(this).parent().data('embed');
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("src", "https://www.youtube.com/embed/" + embed + "?enablejsapi=1&version=3&playerapiid=ytplayer");
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    $(this).parent().append(iframe);
    setTimeout(function () {
        _this.parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }, 500);
});
$(document).on('click', '#video-play-normal', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    $(this).parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');

});

// pause video on click
$(document).on('click', '.navi', function (e) {
    e.preventDefault();
    for (var i = 0; i < $(this).parent().find('iframe').length; i++) {
        $(this).parent().find('iframe')[i].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    }
});


$(function () {
    $('.nav-item').each(function () {
        var windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var navItemWidth = parseInt($('.nav-item').css('width'));
        var navItemPLeft = parseInt($('.nav-item').css('padding-left'));
        var navItemPRight = parseInt($('.nav-item').css('padding-right'));
        var navItemPadding = navItemPLeft + navItemPRight;
        $('.nav-link').each(function () {

            var navLinkWidth = parseInt($(this).css('width'));
            var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 15);
            $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            if (windowWidth < 370) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 20);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }
            if (windowWidth < 290) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 25);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }
            if (windowWidth < 237) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 30);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }

        })
    });
})

function animateCounter() {
    function commaSeparateNumber(val) {
        while (/(\d+)(\d{3})/.test(val.toString())) {
            val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
        }
        return val;
    }

    $('.count').each(function () {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
        }, {
            duration: 8000,
            easing: 'swing',
            step: function (now) {
                $(this).text(commaSeparateNumber(Math.ceil(now)));
            }
        });
    });

}

function countNumber() {
    const optionsCounter = {
        duration: 3,
        separator: '.',
    };
    $('.count[data-count]').each(function () {
        var countTo = Number($(this).data('count'));

        let demo = new CountUp($(this).attr('id'), countTo, optionsCounter);
        if (!demo.error) {
            demo.start();
        } else {
            console.error(demo.error);
        }
    })
}

function horecaBtnPos() {
    $('.horeca_init .slider-content').each(function () {
        var elWidth = $(this).width() / 2;
        var leftPos = 'calc(50% - ' + elWidth + 'px)';
        $(this).css('left', leftPos);
    })
}

// Utility.navbarScroll();

Slider.onInit();